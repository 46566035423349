
          @import "./src/stylesheets/fonts.scss";
          @import "./src/stylesheets/theme.scss";
          @import "./src/stylesheets/common.scss";
          @import "./src/stylesheets/print.scss";
        
@include media-breakpoint-down(md) {
  footer {
    padding: 0 12px;
  }
}
