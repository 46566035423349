
          @import "./src/stylesheets/fonts.scss";
          @import "./src/stylesheets/theme.scss";
          @import "./src/stylesheets/common.scss";
          @import "./src/stylesheets/print.scss";
        
.signup-page-container {
  margin-top: $layout-all-space-top;
  padding: 12px 36px 24px;
  text-align: left; // need to remove text-align: center from App.scss and update styles affected by it

  .signup-page-header {
    border-bottom: 1px solid $gray-200;
    padding-bottom: ($spacer * 1.5);
    .signin-link {
      height: 21px;
      font-weight: $font-weight-medium;
      &:not(:hover) {
        text-decoration: none;
      }
    }
  }

  @include media-breakpoint-down(xxl) {
    padding: 12px 24px 24px;
  }

  @include media-breakpoint-up(lg) {
    margin-bottom: $layout-single-col-space-lgUp-bottom;
  }

  @include media-breakpoint-down(lg) {
    margin-bottom: $layout-single-col-space-lgDown-bottom;
  }

  @include media-breakpoint-down(md) {
    padding: 12px 12px 24px;
  }
}
