
          @import "./src/stylesheets/fonts.scss";
          @import "./src/stylesheets/theme.scss";
          @import "./src/stylesheets/common.scss";
          @import "./src/stylesheets/print.scss";
        
.order-status-info-container {
  border-radius: 0 0 4px 4px;
  padding: 12px 36px 24px 36px;
  align-items: flex-start;
  display: flex;
  background-color: white;

  .order-process-steps {
    background: url('../../../../assets/images/white-wave.png') center center repeat;
    position: relative;
    overflow: hidden;
    padding: 12px 24px;
    border-radius: 12px;
    flex: 1;

    .order-status-card {
      flex: 1 1 0;
      max-width: 340px;
      border-radius: 12px;

      .text-truncate-3 {
        overflow: hidden;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 3;
        line-clamp: 3;
        text-overflow: clip;
      }
    }

    .order-process-step-icon {
      padding: 4px;
      background: $gray-200;
      border-radius: 14px;
      width: 28px;
      height: 28px;
      box-sizing: border-box;
      margin: auto;
    }
  }

  .other-status-card {
    max-width: 340px;
    border: 1px solid rgba(0, 0, 0, 0.175);
    border-radius: 12px;
    flex: 1;
  }

  @include media-breakpoint-down(xxl) {
    padding: 12px 24px 24px;
  }

  @include media-breakpoint-down(lg) {
    .order-process-steps {
      padding: 12px;
    }
  }

  @include media-breakpoint-down(md) {
    padding: 12px 12px 24px;
  }
}
