/* IBM-Plex-Sans Font */
@font-face {
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 400;
  src: url('../assets/fonts/IBM-Plex-Sans/IBMPlexSans-Regular.woff2');
}

@font-face {
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 500;
  src: url('../assets/fonts/IBM-Plex-Sans/IBMPlexSans-Medium.woff2');
}
