
          @import "./src/stylesheets/fonts.scss";
          @import "./src/stylesheets/theme.scss";
          @import "./src/stylesheets/common.scss";
          @import "./src/stylesheets/print.scss";
        
.expandable-section {
  border-radius: 4px;
  border: none;

  .section-header {
    padding: 12px 36px 8px 24px;

    .section-header-title {
      display: flex;
      align-items: center;
      width: 100%;
      font-size: 24px;
      line-height: 1.2;
      font-weight: 700;
      text-align: left;
    }
  }

  .expandable-content {
    border-top: 1px solid rgba(0, 0, 0, 0.175);
    padding: 24px 36px;

    &:not(.show) {
      display: none !important;
    }
  }

  @include media-breakpoint-down(xxl) {
    .section-header {
      padding: 12px 24px 8px 12px;
    }

    .expandable-content {
      padding: 24px;
    }
  }

  @include media-breakpoint-down(lg) {
    .expandable-content {
      padding: 16px 24px;
    }
  }

  @include media-breakpoint-down(md) {
    .section-header {
      padding: 12px 12px 8px 0;

      .section-header-title {
        font-size: 20px;
      }
    }

    .expandable-content {
      padding: 16px 12px;
    }
  }
}
