
          @import "./src/stylesheets/fonts.scss";
          @import "./src/stylesheets/theme.scss";
          @import "./src/stylesheets/common.scss";
          @import "./src/stylesheets/print.scss";
        
.requisitions-table-container {
  height: 460px;

  .ag-root-wrapper {
    border-radius: 0;
  }

  &.status-panel {
    height: 300px;
  }

  .ag-cell-value {
    text-align: left;

    & > a {
      text-decoration: unset;
      font-weight: 500;
    }
  }

  .results-cell .ag-cell-value {
    text-align: center;
  }

  .download-menu-col-header {
    padding: {
      left: 0;
      right: 0;
    }
  }

  .download-menu-cell {
    padding: {
      left: 0;
      right: 0;
    }
  }
}

.requisitions-status-panel {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 16px;
  border: 1px solid #c7f0f4;
  border-radius: var(--bs-border-radius);
  background: #f6fcfe;

  & > * {
    align-self: center !important;
  }
}
