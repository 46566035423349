
          @import "./src/stylesheets/fonts.scss";
          @import "./src/stylesheets/theme.scss";
          @import "./src/stylesheets/common.scss";
          @import "./src/stylesheets/print.scss";
        
.patient-detail-page {
  .patient-detail-content {
    margin: 24px 0 80px;

    .patient-detail-info {
      padding: 12px 36px 24px;

      .patient-age-min {
        padding: 2px !important;
        border-radius: 0 !important;
        background-color: #cff4fc !important;
        color: #055160;
      }

      .patient-detail-cell {
        min-width: 180px;
      }
    }

    .no-patient-detail-content {
      padding: 32px 16px;
    }
  }

  @include media-breakpoint-down(xxl) {
    .patient-detail-content {
      .patient-detail-info {
        padding: 12px 24px 24px;
      }
    }
  }

  @include media-breakpoint-down(lg) {
    .patient-detail-content {
      margin: 16px 0 56px;

      .patient-detail-info {
        .patient-detail-cell {
          min-width: 140px;
        }
      }
    }
  }

  @include media-breakpoint-down(md) {
    .patient-detail-content {
      .patient-detail-info {
        padding: 12px 12px 24px;

        .patient-detail-cell {
          min-width: 120px;

          .cell-label {
            font-size: 14px !important;
          }

          .cell-value {
            font-size: 16px !important;
          }
        }
      }
    }
  }
}
