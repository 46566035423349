
          @import "./src/stylesheets/fonts.scss";
          @import "./src/stylesheets/theme.scss";
          @import "./src/stylesheets/common.scss";
          @import "./src/stylesheets/print.scss";
        
.patient-detail-order {
  .order-cards {
    .patient-detail-order-card {
      max-width: 360px;
      min-width: 300px;
      border-radius: 6px;

      &.border-light {
        border-color: rgba(0, 0, 0, 0.175) !important;
      }

      .card-header {
        border-radius: 5px 5px 0 0;
        display: flex;
        align-items: center;
        font-weight: 700;
        border-bottom: 1px solid rgba(0, 0, 0, 0.175);
      }

      .card-body {
        border-radius: 0 0 5px 5px;

        .order-test-name {
          & > p {
            margin-bottom: 0;
          }
        }
      }
    }

    @include media-breakpoint-only(xs) {
      margin: 8px -8px -8px;
    }
  }
}

.order-info-dialog {
  ul.order-status-list {
    padding-left: 22px;
  }

  ul.results-report-list {
    padding: 6px 0 4px;
    list-style-type: none;
    gap: 6px;
  }
}
