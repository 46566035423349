
          @import "./src/stylesheets/fonts.scss";
          @import "./src/stylesheets/theme.scss";
          @import "./src/stylesheets/common.scss";
          @import "./src/stylesheets/print.scss";
        
.patient-detail-sidebar {
  position: fixed;
  top: 140px;
  width: 246px;

  .return-btn {
    color: $dark;
    text-decoration: unset !important;
  }

  .sections-navbar {
    list-style: none;

    .sidebar-item-placeholder {
      width: 236px;
    }
  }

  @include media-breakpoint-only(xl) {
    width: 236px;
  }

  @include media-breakpoint-only(lg) {
    width: 192px;
  }

  @include media-breakpoint-down(lg) {
    width: inherit;

    .nav {
      margin-top: 4px;

      .sidebar-item-placeholder {
        width: 80px;
        padding: 8px 0 !important;
        box-sizing: content-box;
      }

      li {
        position: relative;

        & > * {
          padding: 8px;
          margin: 0 8px;
        }

        &:not(:first-child) > *::before {
          content: '';
          position: absolute;
          left: 0;
          top: 4px;
          bottom: 4px;
          width: 1px;
          background-color: $gray-700;
          opacity: 0.25;
        }
      }
    }
  }

  @include media-breakpoint-only(md) {
    top: calc($header-height-offset + $access-banner-height + 16px);
  }

  @include media-breakpoint-down(md) {
    top: calc($header-height-offset-mobile + $access-banner-height-mobile + 16px);
  }
}
