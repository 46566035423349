
          @import "./src/stylesheets/fonts.scss";
          @import "./src/stylesheets/theme.scss";
          @import "./src/stylesheets/common.scss";
          @import "./src/stylesheets/print.scss";
        
.account-review-wrapper {
  padding: 36px 24px 24px;

  h1 {
    font-size: 28px;
    line-height: 33.6px;
  }

  @include media-breakpoint-down(md) {
    padding: 36px 12px 24px;
  }
}
