
          @import "./src/stylesheets/fonts.scss";
          @import "./src/stylesheets/theme.scss";
          @import "./src/stylesheets/common.scss";
          @import "./src/stylesheets/print.scss";
        
.new-reports {
  .report-card {
    max-width: 326px;
    border-color: rgba(0, 0, 0, 0.175);
    border-radius: 6px;

    .card-body {
      border-radius: 5px;

      ul.report-products {
        list-style-type: disc;
        padding-left: 20px;

        & > li::marker {
          font-size: 8px;
        }
      }
    }
  }
}
