
          @import "./src/stylesheets/fonts.scss";
          @import "./src/stylesheets/theme.scss";
          @import "./src/stylesheets/common.scss";
          @import "./src/stylesheets/print.scss";
        
.cp-toast-container {
  right: 0;

  .cp-toast-default {
    .toast-header {
      background-color: unset;
    }
  }
}
