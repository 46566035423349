
          @import "./src/stylesheets/fonts.scss";
          @import "./src/stylesheets/theme.scss";
          @import "./src/stylesheets/common.scss";
          @import "./src/stylesheets/print.scss";
        
.page-left-content {
  padding: 24px 24px 24px 0;

  @include media-breakpoint-up(xxl) {
    flex: 0 0 270px;
  }

  @include media-breakpoint-only(xl) {
    flex: 0 0 260px;
  }

  @include media-breakpoint-only(lg) {
    flex: 0 0 216px;
  }

  @include media-breakpoint-down(lg) {
    flex: 0 0 68px;
    padding: 16px 0 12px;
    z-index: 1;
  }
}
