
          @import "./src/stylesheets/fonts.scss";
          @import "./src/stylesheets/theme.scss";
          @import "./src/stylesheets/common.scss";
          @import "./src/stylesheets/print.scss";
        
.fset-additional-account {
  padding-left: $spacer;
  padding-right: $spacer;

  &:nth-of-type(odd) {
    background-color: $bg-tertiary;
    border-radius: $border-radius;
    padding-top: $spacer;
    padding-bottom: $spacer * 1.5;
  }

  &:nth-of-type(even) {
    padding-bottom: $spacer;
  }

  & > *:not(legend) {
    @include media-breakpoint-up(md) {
      padding-left: $spacer * 0.5;
      padding-right: $spacer * 0.5;
    }
  }
}
