
          @import "./src/stylesheets/fonts.scss";
          @import "./src/stylesheets/theme.scss";
          @import "./src/stylesheets/common.scss";
          @import "./src/stylesheets/print.scss";
        
.requisition-complete-container {
  section {
    .fa-circle-check {
      color: $success;
      margin: 28px 0 16px;
    }

    .requisition-complete-actions {
      @include media-breakpoint-down(sm) {
        flex-direction: column;

        & > button {
          width: 251px;
          justify-content: center;
        }
      }
    }
  }
}
