
          @import "./src/stylesheets/fonts.scss";
          @import "./src/stylesheets/theme.scss";
          @import "./src/stylesheets/common.scss";
          @import "./src/stylesheets/print.scss";
        
.banner-container {
  height: 36px;

  &.catalyst {
    background: linear-gradient(90deg, rgba(50, 33, 166, 1) 0%, rgba(37, 232, 113, 1) 100%);
  }

  .banner-content {
    gap: 6px;

    .product-name {
      @include media-breakpoint-only(md) {
        font-size: 18px;
      }
    }

    .company-brand {
      font-size: 9px;
      line-height: 10.74px;
      padding: 10px 0 3px;
    }
  }
}
