
          @import "./src/stylesheets/fonts.scss";
          @import "./src/stylesheets/theme.scss";
          @import "./src/stylesheets/common.scss";
          @import "./src/stylesheets/print.scss";
        
.document-download-menu-wrapper {
  width: inherit;
  text-align: center;
}

.document-download-menu {
  width: 240px;
  @include media-breakpoint-down(md) {
    max-height: 400px;
    overflow-y: scroll;
  }

  .dropdown-item {
    &:focus {
      // gives :focus-visible style to :focus menu item
      outline: -webkit-focus-ring-color auto 1px;
      &:not(:hover) {
        // resets background-color of :focus menu item to bootstrap default
        // avoids confusion with hover state
        background-color: #0000;
      }
    }
    & > span {
      text-wrap: wrap;
    }
  }
}

.document-download-menu-toggle {
  --bs-btn-focus-box-shadow: 0 0 0 0.25rem rgba(var(--bs-btn-focus-shadow-rgb), 0.5);
  color: #181d1f; // var(--ag-foreground-color)
  font-family: inherit;
  line-height: inherit;
  background-image: none;
  background-color: transparent;
  border-width: 0;
  padding: 0 0.5rem;
  &:hover {
    color: #2196f3; // var(--ag-active-color)
  }
  &:active,
  &.show {
    color: #2196f3;
  }
  &:focus,
  &:focus-visible {
    box-shadow: var(--bs-btn-focus-box-shadow);
  }
  &:disabled {
    color: rgba(24, 29, 31, 0.5); // var(--ag-text-disabled)
  }
}

li + .document-download-li:not(:last-of-type) {
  &:after {
    height: 1px;
    content: '';
    background-color: var(--bs-dropdown-divider-bg);
    display: block;
    margin: ($spacer * 0.25) var(--bs-dropdown-item-padding-x);
  }
}

.document-download-li .dropdown-item:active *.text-second {
  color: inherit;
}
