
          @import "./src/stylesheets/fonts.scss";
          @import "./src/stylesheets/theme.scss";
          @import "./src/stylesheets/common.scss";
          @import "./src/stylesheets/print.scss";
        
.my-delegates-container {
  .page-title-section {
    ul {
      padding-left: 20px;
    }
  }

  .expandable-content {
    ul,
    form {
      max-width: 412px;

      @include media-breakpoint-down(md) {
        max-width: unset;
        width: 100%;
      }

      li {
        text-align: left;
      }
    }
  }
}
