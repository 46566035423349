
          @import "./src/stylesheets/fonts.scss";
          @import "./src/stylesheets/theme.scss";
          @import "./src/stylesheets/common.scss";
          @import "./src/stylesheets/print.scss";
        
.patient-detail-skeleton {
  .breadcrumbs {
    height: 24px;

    & > * {
      width: 80px;
    }
  }

  .skeleton-section {
    padding: 12px 36px 24px;
    display: flex;
    flex-direction: column;
    gap: 24px;
    border-radius: 4px;

    @include media-breakpoint-down(lg) {
      padding: 12px 24px 24px;
      gap: 16px;
    }

    @include media-breakpoint-down(md) {
      padding: 12px 12px 24px;
    }
  }

  .w-180 {
    width: 180px;
  }

  .card-2 {
    width: 231px;

    @include media-breakpoint-down(lg) {
      width: 100%;

      & > span:first-child {
        width: 107px !important;
      }
      & > span:last-child {
        width: 215px !important;
      }
    }
  }

  .w-360 {
    width: 360px;

    @include media-breakpoint-down(lg) {
      width: 50%;
    }
    @include media-breakpoint-down(lg) {
      width: 100%;
    }
  }

  .expandable-section.skeleton {
    .section-header {
      padding: 12px 36px 8px;

      @include media-breakpoint-down(lg) {
        padding: 12px 24px 8px;
      }
      @include media-breakpoint-down(md) {
        padding: 12px 12px 8px;
      }
    }

    .expandable-content {
      padding: 24px 36px;

      &.new-reports-content {
        & > div {
          padding: 32px 16px;

          & > span:first-of-type {
            width: 600px;

            @include media-breakpoint-down(lg) {
              width: 100%;
            }
          }
          & > span:last-of-type {
            width: 200px;
          }
        }
      }

      @include media-breakpoint-down(lg) {
        padding: 16px 24px 24px;
      }
      @include media-breakpoint-down(md) {
        padding: 16px 12px 24px;
      }
    }
  }
}
