
          @import "./src/stylesheets/fonts.scss";
          @import "./src/stylesheets/theme.scss";
          @import "./src/stylesheets/common.scss";
          @import "./src/stylesheets/print.scss";
        
.error-container {
  background: url('../../assets/images/white-wave_light-blue-bg.png') center center repeat;

  .error-content {
    background: url('../../assets/images/wave-cut_blue-bg.png') center center repeat;

    .card {
      background-color: rgba(255, 255, 255, 0.6);
    }
  }
}
