
          @import "./src/stylesheets/fonts.scss";
          @import "./src/stylesheets/theme.scss";
          @import "./src/stylesheets/common.scss";
          @import "./src/stylesheets/print.scss";
        
.order-report-section {
  padding: 12px 36px 24px;

  .order-cards {
    gap: 36px;
  }

  .order-status {
    border-color: $myo-subtle2 !important;

    &--value {
      color: $primary;

      &.error {
        color: $myo-subtle2;
      }
    }

    &--title {
      line-height: 24px;
      width: 197px;
    }

    @include media-breakpoint-only(md) {
      &--title {
        width: 160px;
      }
    }

    @include media-breakpoint-only(sm) {
      &--title {
        width: 129px;
        font-size: 16px;
        line-height: 19.2px;
      }
    }

    @include media-breakpoint-only(xs) {
      &--title {
        width: 100%;
      }
    }
  }
}

.patient-toggle-btn {
  svg {
    width: 20px;
  }
}

.order-status-collapse:not(.show) {
  display: block !important;
  visibility: hidden;
  position: absolute;
}

@include media-breakpoint-down(xxl) {
  .order-report-section {
    padding: 12px 24px 24px;
  }
}

@include media-breakpoint-down(md) {
  .order-report-section {
    padding: 12px 12px 24px;

    h1 {
      font-size: 32px;
      line-height: 38.4px;
    }

    .order-cards {
      gap: 16px;
    }
  }
}
