
          @import "./src/stylesheets/fonts.scss";
          @import "./src/stylesheets/theme.scss";
          @import "./src/stylesheets/common.scss";
          @import "./src/stylesheets/print.scss";
        
.fset,
.nested-fset {
  @include media-breakpoint-down(lg) {
    & > .vstack > .row {
      // so form fields within same row have space between them matching row gap of the stack
      row-gap: $spacer * 0.75;
    }
  }
}

.signup-form-bottom {
  border-top: 1px solid $gray-200;
  padding-top: ($spacer * 2.25);
}
