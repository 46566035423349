.default_page_layout {
  height: calc(100vh - 145px);
  margin: 135px auto 36px auto;
  max-width: 900px;
}

.page-title-section {
  background-color: $body-bg;
  border-radius: $border-radius-sm;
  gap: 12px;
  padding: 12px 36px 24px;
  @extend .box-shadow-50;
  @include media-breakpoint-down(xxl) {
    padding: 12px 24px 24px;
  }
  @include media-breakpoint-down(md) {
    padding: 12px 12px 24px;
  }
}

.page-title {
  font-size: 40px;
  line-height: 48px;

  @include media-breakpoint-only(md) {
    font-size: 32px;
    line-height: 38.4px;
  }

  @include media-breakpoint-down(md) {
    font-size: 28px !important;
    line-height: 33.6px !important;
  }
}

.page-subtitle {
  font-size: 14px;
  line-height: 21px;
}

.text-left {
  text-align: left;
}

.fs-body {
  font-size: $font-size-sm;
  line-height: 1.5;

  &.small {
    font-size: $font-size-xs;
  }
}

.fs-sm {
  font-size: $font-size-sm;
}
.fs-xs {
  font-size: $font-size-xs;
}

.lh-xs {
  line-height: 1.2;
}

.text-second {
  color: $text-secondary;
}

.border-cyan {
  border-color: $myo-cyan !important;
}

.box-shadow-50 {
  box-shadow: 0 1px 4px 0px rgba(0, 0, 0, 0.1);
}

.box-shadow-100 {
  box-shadow: 0 8px 10px -4px rgba(0, 0, 0, 0.1);
}

.container-fluid {
  padding: 0 12px;

  @include media-breakpoint-only(xl) {
    padding: 0 72px;
  }

  @include media-breakpoint-only(lg) {
    padding: 0 44px;
  }

  @include media-breakpoint-only(md) {
    padding: 0 36px;
  }

  @include media-breakpoint-down(md) {
    padding: 0 16px;
  }
}

// below used for pages with central single column
.layout-single-col-py {
  padding-top: $layout-all-space-top;
  @include media-breakpoint-down(lg) {
    padding-bottom: $layout-single-col-space-lgDown-bottom;
  }
  @include media-breakpoint-up(lg) {
    padding-bottom: $layout-single-col-space-lgUp-bottom;
  }
}

.hr {
  width: 100%;
  height: 1px;
  background-color: $gray-300;
}

.gap-6p {
  gap: 6px;
}

.gap-12 {
  gap: 12px;
}

.bg-tertiary {
  background-color: $bg-tertiary;
}

.list-style-none {
  list-style: none;
}

.text-decoration-none {
  text-decoration: none;
}

.form-label.required {
  &:after {
    content: '*';
  }
}

.legend {
  font-size: $font-size-lg;
  font-weight: $font-weight-medium;
}
.legend-label {
  font-size: $font-size-base;
  font-weight: $font-weight-medium;
}

svg.primary {
  color: $primary;
}

// ghost is custom, use with <Button variant="ghost">
.btn-ghost {
  --bs-btn-color: var(--bs-body-color);
  --bs-btn-bg: transparent;
  --bs-btn-border-color: transparent;
  --bs-btn-hover-color: var(--bs-body-color);
  --bs-btn-hover-bg: #{$bg-tertiary};
  --bs-btn-hover-border-color: #{$bg-tertiary};
  --bs-btn-focus-shadow-rgb: var(--bs-dark-rgb);
  --bs-btn-active-color: var(--bs-btn-hover-color);
  --bs-btn-active-bg: transparent;
  --bs-btn-active-border-color: transparent;
  &:focus {
    background-color: transparent;
    border-color: transparent;
  }
}

// below allows <Button variant="teal"> to have correct colors
.btn-teal {
  --bs-btn-color: var(--bs-white);
  --bs-btn-bg: #{$myo-teal};
  --bs-btn-border-color: #{$myo-teal};
  --bs-btn-hover-color: var(--bs-white);
  --bs-btn-hover-bg: #{shade-color($myo-teal, $btn-hover-bg-shade-amount)};
  --bs-btn-hover-border-color: #{shade-color($myo-teal, $btn-hover-border-shade-amount)};
  --bs-btn-focus-shadow-rgb: #{$myo-teal-rgb};
  --bs-btn-active-color: var(--bs-btn-hover-color);
  --bs-btn-active-bg: #{shade-color($myo-teal, $btn-active-bg-shade-amount)};
  --bs-btn-active-border-color: #{shade-color($myo-teal, $btn-active-border-shade-amount)};
}

.dropdown-menu {
  --bs-dropdown-header-color: #{$secondary};
}

.expandable-content {
  .no-content {
    width: 100%;
    height: 140px;
    display: flex;
    align-items: center;
    padding: 16px;
    justify-content: center;
    border-radius: var(--bs-border-radius-sm);

    p {
      text-align: center;
      font-weight: 500;
      font-size: 20px;
      line-height: 24px;
      margin-bottom: 0;

      &.second-line {
        font-size: 16px;
        font-weight: normal;
      }
    }
  }
}

.rbt {
  flex: 1;

  a.dropdown-item {
    mark {
      padding: 0;
      margin-bottom: 2px;
      display: inline-block;
    }
  }
}

// Use the table-with-stick-header class on <Table> to get a sticker header when scrolling
// can use with default <Table> component and with react-bs-datatable
// DO NOT USE ON AG-GRID
.table-with-sticky-header {
  & th {
    position: sticky;
    top: -1px;
    // below creates illusion of a bottom border because borders are lost on sticky table heads
    background-image: linear-gradient($body-bg, $body-bg), linear-gradient($border-color, $border-color);
    background-repeat: no-repeat;
    background-position:
      0 0,
      0 0;
    background-size:
      100% calc(100% - 1px),
      100% 100%;
    // since bottom border gets lost during scroll we make the color transparent
    // so it doesn't double up before scroll
    border-bottom-color: transparent;
  }
}
