
          @import "./src/stylesheets/fonts.scss";
          @import "./src/stylesheets/theme.scss";
          @import "./src/stylesheets/common.scss";
          @import "./src/stylesheets/print.scss";
        
.documents-list {
  display: grid;
  grid-template-columns: 1fr 1fr;

  @include media-breakpoint-down(md) {
    display: flex;
    flex-direction: column;
  }
}
