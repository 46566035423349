
          @import "./src/stylesheets/fonts.scss";
          @import "./src/stylesheets/theme.scss";
          @import "./src/stylesheets/common.scss";
          @import "./src/stylesheets/print.scss";
        
header {
  .access-banner {
    background-color: $myo-teal-light;
    height: $access-banner-height;
    box-sizing: border-box;

    &-content {
      width: 100%;

      .partnership-ordering-text {
        font-size: 10px;
        line-height: 1.5;
      }

      svg {
        width: 16px;
        height: 16px;
      }
    }
  }

  .main-navbar {
    height: $header-height-offset;

    .toggle-btn {
      padding: 5px 12px !important;
      height: 36px;

      & > .navbar-toggler-icon {
        width: 24px;
        height: 24px;
      }
    }

    .navbar-collapse {
      .vr {
        margin: 6px 0;
      }
    }
  }

  .profile-icon {
    color: $primary;
  }

  .clinian-info.no-info {
    min-width: 153px;
  }

  & + *,
  & ~ .cp-toast-container {
    margin-top: 116px;
  }

  .group-setting {
    &-title {
      padding: 8px;
      font-size: 14px;
      line-height: 1.2;
      color: $secondary;
      font-weight: 500;
    }

    &-item {
      padding: 6px 8px;
    }
  }
}

@include media-breakpoint-down(md) {
  header {
    .access-banner {
      height: 16px;

      &-content {
        flex-direction: row !important;
      }
    }

    .main-navbar {
      height: unset;
      padding: 12px 0;

      .navbar-collapse {
        .new-order-btn {
          width: 100%;
        }
      }
    }

    .logout-border {
      height: 1px;
      opacity: 0.25;
      width: 100%;
      background-color: $text-secondary;
      margin: 0 !important;
      min-height: 1px;
    }

    & + *,
    & ~ .cp-toast-container {
      margin-top: $header-height-offset-mobile;
    }

    &:not(.has-partnership-ordering) + .page-container::before {
      top: $header-height-offset-mobile;
    }

    &.has-partnership-ordering + *,
    & ~ .cp-toast-container {
      margin-top: calc($header-height-offset-mobile + 16px);
    }
  }
}
