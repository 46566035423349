
          @import "./src/stylesheets/fonts.scss";
          @import "./src/stylesheets/theme.scss";
          @import "./src/stylesheets/common.scss";
          @import "./src/stylesheets/print.scss";
        
.progress {
  background: url('../../assets/images/white-wave.png') center center repeat;
  background-color: $myo-subtle;
  position: relative;
  overflow: hidden;
}

.progress::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(90deg, rgba(0, 53, 149, 0) 0%, rgba(0, 53, 149, 0) 60%, rgba(0, 53, 149, 1) 100%);
  animation: loading 1.5s infinite;
}

@keyframes loading {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(100%);
  }
}
