
          @import "./src/stylesheets/fonts.scss";
          @import "./src/stylesheets/theme.scss";
          @import "./src/stylesheets/common.scss";
          @import "./src/stylesheets/print.scss";
        
.page-right-content {
  flex: 1;
  background-color: $light;
  padding-left: 24px;

  @include media-breakpoint-down(lg) {
    padding-left: 0;
  }
}
