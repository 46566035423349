
          @import "./src/stylesheets/fonts.scss";
          @import "./src/stylesheets/theme.scss";
          @import "./src/stylesheets/common.scss";
          @import "./src/stylesheets/print.scss";
        
.create-requisition-container {
  .custom-select {
    @extend .form-select;
  }

  .card {
    &-subtitle,
    &-body {
      max-width: 936px;
    }
  }
}
