
          @import "./src/stylesheets/fonts.scss";
          @import "./src/stylesheets/theme.scss";
          @import "./src/stylesheets/common.scss";
          @import "./src/stylesheets/print.scss";
        
.draft-orders-container {
  .page-title-section {
    .page-subtitle {
      max-width: 936px;
    }
  }

  .table-responsive {
    max-height: 400px;

    table {
      margin-bottom: 0;

      thead {
        th {
          white-space: nowrap;
        }
      }

      tbody {
        td {
          white-space: nowrap;
        }
      }
    }

    @include media-breakpoint-down(md) {
      max-height: 300px;
    }
  }
}
