
          @import "./src/stylesheets/fonts.scss";
          @import "./src/stylesheets/theme.scss";
          @import "./src/stylesheets/common.scss";
          @import "./src/stylesheets/print.scss";
        
ul.selected-users,
form.add-delegate-form {
  max-width: 412px;

  .rbt {
    input.rbt-input {
      border-radius: 6px 0 0 6px;
    }
  }

  @include media-breakpoint-down(md) {
    max-width: unset;
    width: 100%;
  }

  li {
    text-align: left;
  }
}
