
          @import "./src/stylesheets/fonts.scss";
          @import "./src/stylesheets/theme.scss";
          @import "./src/stylesheets/common.scss";
          @import "./src/stylesheets/print.scss";
        
.requisition-receipt-content {
  max-width: 540px;
  border-radius: 8px;

  & > li.list-group-item {
    background-color: $white;
    border-color: $gray-300;
    padding: 0.5rem 1rem;
    text-align: left;
    color: $dark;

    & > p {
      line-height: 1.2;
    }

    ul.unordered-receipt-item {
      list-style-type: disc;

      & > li::marker {
        font-size: 8px;
      }
    }
  }

  @include media-breakpoint-down(md) {
    width: 100%;
    flex: 1;
  }
}
