
          @import "./src/stylesheets/fonts.scss";
          @import "./src/stylesheets/theme.scss";
          @import "./src/stylesheets/common.scss";
          @import "./src/stylesheets/print.scss";
        
.page-container {
  &::before {
    content: '';
    width: 50%;
    background-color: $white;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    z-index: -1;
  }
  &::after {
    content: '';
    width: 50%;
    background-color: $light;
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: -1;
  }

  div.page-content {
    width: 100%;
  }

  @include media-breakpoint-down(md) {
    &::before {
      top: $header-height-offset-mobile;
    }
  }

  @include media-breakpoint-only(md) {
    &::before {
      top: $header-height-offset;
    }
  }

  @include media-breakpoint-down(lg) {
    &::before {
      content: '';
      width: 100%;
      height: 68px;
      position: fixed;
      z-index: 1;
    }
    &::after {
      content: '';
      width: 100%;
      top: 68px;
      bottom: 0;
    }
  }
}

header.has-access-banner + .page-container {
  @include media-breakpoint-down(md) {
    &::before {
      top: calc($header-height-offset-mobile + $access-banner-height-mobile);
    }
  }

  @include media-breakpoint-only(md) {
    &::before {
      top: calc($header-height-offset + $access-banner-height);
    }
  }
}
