
          @import "./src/stylesheets/fonts.scss";
          @import "./src/stylesheets/theme.scss";
          @import "./src/stylesheets/common.scss";
          @import "./src/stylesheets/print.scss";
        
.custom-floating-filter {
  .floating-trigger {
    button {
      text-decoration-line: none !important;
    }
  }
}

.floating-filter-card {
  .card-body {
    padding: 0;
    line-height: 1.5;

    .filter-body {
      padding: var(--ag-widget-container-vertical-padding) var(--ag-widget-container-horizontal-padding);
    }

    .filter-footer {
      padding: var(--ag-widget-container-vertical-padding) var(--ag-widget-container-horizontal-padding);
      border-top: var(--ag-borders) var(--ag-border-color);
      list-style-type: none;
      margin-bottom: 0;
    }
  }
}
