
          @import "./src/stylesheets/fonts.scss";
          @import "./src/stylesheets/theme.scss";
          @import "./src/stylesheets/common.scss";
          @import "./src/stylesheets/print.scss";
        
.placeholder-root {
  line-height: 0;

  .placeholder {
    background-color: $myo-subtle;

    &.placeholder-xs {
      height: 10px !important;
    }
    &.placeholder-sm {
      height: 16px !important;
    }
    &.placeholder-lg {
      height: 19px !important;
    }
    &.placeholder-xl {
      height: 32px !important;
    }
    &.placeholder-2xl {
      height: 36px !important;
    }
    &.placeholder-3xl {
      height: 164px !important;
    }
  }
}
