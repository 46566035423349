
          @import "./src/stylesheets/fonts.scss";
          @import "./src/stylesheets/theme.scss";
          @import "./src/stylesheets/common.scss";
          @import "./src/stylesheets/print.scss";
        
.user-management-container {
  .page-title-section {
    .page-subtitle {
      max-width: 936px;
    }
  }

  .rbt {
    max-width: 438px;
    width: 100%;
  }

  .table-responsive {
    max-height: 400px;

    table {
      margin-bottom: 0;

      thead {
        th {
          white-space: nowrap;
        }
      }

      tbody {
        .user-edit-btn {
          height: 29px;
          width: 32px;
        }

        td {
          white-space: nowrap;
        }
      }
    }

    @include media-breakpoint-down(md) {
      max-height: 300px;
    }
  }
}
